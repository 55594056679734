.formContent {
  display: flex;
  flex-direction: column;
  padding-top: 40px;
  gap: 32px;
  max-width: 1040px;
  width: 100%;

  .input1 {
    display: flex;
    flex-direction: column;
    gap: 16px;
    .Q1 {
      display: flex;
      flex-direction: row;
      gap: 4px;
      .text {
        color: #111;

        /* Subtitle3  KR Semibold */
        font-family: Pretendard;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 150% */
        letter-spacing: -0.4px;
      }
      .star {
        color: var(--500, #3d8491);
        font-family: Pretendard;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 150% */
      }
    }
    .input-field {
      .input-field-number {
        display: flex;
        flex-direction: column;
        gap: 12px;
        .div1 {
          display: flex;

          // align-items: center;
          // justify-content: center;
          gap: 8px;
          .A1 {
            width: 100%;
            display: flex;
            .input-field {
              display: flex;
              max-width: 932px;
              width: 100%;
              aspect-ratio: 20.04347;
            }
          }
          .button {
            .button1 {
              padding: 13px 17px 13px 18px;

              border-radius: 8px;
              background: var(--sub1, #ebf5f2);
              max-width: 110px;
              width: 100%;
              height: 46px;
              max-height: 100%;

              .textB {
              }
            }
          }
        }
        .A1 {
          .input-field {
          }
        }
      }
    }
    .A1 {
      .input-container-number {
        display: flex;
        flex-direction: column;

        .div1 {
          display: flex;
          gap: 8px;
          .input {
          }
          .button1 {
            display: inline-flex;
            padding: 13px 18px 13px 17px;
            justify-content: center;
            align-items: center;
            border-radius: 8px;
            background: var(--sub1, #ebf5f2);

            max-width: 110px;
            width: 100%;

            .textB {
              color: var(--800, #154d57);
              font-family: Pretendard;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px; /* 142.857% */
              letter-spacing: -0.35px;
            }
          }
        }
      }
    }
  }
}
.l {
  display: flex;
  gap: 4px;
  align-items: flex-end;
  max-width: 1040px;
  width: 100%;

  .star {
    color: var(--600, #317580);
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
  }
  .w {
    color: #111;
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: -0.4px;
  }
}

.radio {
  display: flex;
  flex-direction: row;
  gap: 20px;
  .input1 {
    display: flex;
    gap: 6px;
  }
  .input2 {
    display: flex;
    gap: 6px;
  }
}

.ant-select{
  width: 100% !important;
  height: 46px !important;
  
}
.ant-select-focused{
  .ant-select-selector{
   border:  1px solid #d9d9d9 !important;
  outline: none !important;
  }
}
.ant-select-item{
  height: 46px !important;
  display: flex;
  align-items: center;

  &[aria-selected="true"]{
    background-color: #EBF5F2 !important;
  }
}

span.ant-select-selection-item{
color: #767676;
}