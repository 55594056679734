.disabled,
.diabled {
  /* display: none !important; */
  opacity: 0.3;
  cursor: not-allowed !important;
}
.click {
  user-select: all;
  cursor: not-allowed;
}
