.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

button:disabled,
button[disabled] {
  background-color: #cccccc;
  color: #666666;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* *{
  position: relative;
} */

tr {
  cursor: pointer;

}

.help-desk-container {
  pointer-events: none;
  position: fixed;
  inset: 0;

  .help {
    pointer-events: all;
    background-color: white;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    padding-inline: 20px;
    padding-block: 10px;
    position: absolute;
    bottom: 20px;
    right: 20px;
    transition: all 1s ease;

    * {
      cursor: pointer;
    }
  }
}

input[type="checkbox"] {
  box-shadow: none !important;

}

* {
  accent-color: #5A97A3 !important;
}

body[data-visible="true"] {
  tr.text-center.cursor-pointer.hover\:bg-\[\#EBF5F2\],tr.text-left.cursor-pointer.hover\:bg-\[\#EBF5F2\] {
    display: none !important;
  }
}

.flex.flex-row.w-full.justify-between{
  div{
    width: 80px;
  }

  div:nth-child(2){
    // justify-content: center;
    padding-left: 10px;
    display: flex;
  }
}

.pr-\[15px\].py-\[20px\].pl-\[20px\].border-\[1px\]::-webkit-scrollbar{
  height: 5px;
}

.flex.flex-row.w-full.justify-between {
  margin-left: 15px;
}

.font-\[\'pretendard\'\].text-\[13px\].font-\[400\].leading-\[19px\].tracking-\[-0\.325px\].w-max {
  width: 70px;
}

div.flex.gap-\[20px\].pl-\[36px\] > div.flex.flex-col.gap-\[16px\].w-full > div > div.flex.gap-\[16px\] > div.flex.gap-\[20px\] > div{
  width: 70px;
}

input[placeholder="할인율(%) or 할인금액을 입력해주세요"]{
  padding: 0 !important;
}