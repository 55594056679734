.time-container {
  height: 156px;
  display: flex;
  overflow: hidden;
  gap: 32px;
  
}
.time-overlay{
  transition: all 250ms ease;
  position: absolute;
  z-index: 10000;
  top:100%;
  left: 0;
  transform-origin: top;
  border-radius: 8px;
border: 1px solid #D4D4D8;
background: var(--FFFFFF, #FFF);
}

.am-pm-container > div {
  cursor: pointer;
  height: 52px;
  justify-content: center;
  align-items: center;
  display: flex;
  color: rgba(17, 17, 17, 0.25);
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: -0.4px;
}

.am-pm-container .active {
  color: #111;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: -0.4px;
}

.digits {
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
  scroll-padding: 0 8px;
  user-select: none;

}
.digits:hover{
  cursor:pointer;
} 
.time-item {
  scroll-snap-align: center;
  height: 52px;
  justify-content: flex-end;
  align-items: center;
  display: flex;
  gap: 16px;
  /* 137.5% */
  color: rgba(17, 17, 17, 0.25);
  font-family: Pretendard;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 44px; /* 137.5% */
}
.digits::-webkit-scrollbar {
  display: none;
}

.time-item.active {
  color: #111 !important;
  font-family: Pretendard;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 44px;
}
.time-item:not(.active) .seperator {
  opacity: 0;
}

.time-container * {
  transition: all 250ms ease;
}
