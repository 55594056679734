.formContent {
  display: flex;
  flex-direction: column;
  padding-top: 40px;
  gap: 32px;
  max-width: 1040px;
  width: 100%;

  .input1 {
    display: flex;
    flex-direction: column;
    gap: 16px;
    max-width: 1040px;
    width: 100%;

    //aspect-ratio: 22.60869;
    .Q1 {
      display: flex;
      flex-direction: row;
      gap: 4px;
      .text {
        color: #111;

        /* Subtitle3  KR Semibold */
        font-family: Pretendard;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 150% */
        letter-spacing: -0.4px;
      }
      .star {
        color: var(--500, #3d8491);
        font-family: Pretendard;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 150% */
      }
    }
    .A1 {
      .ButtonUpload {
        max-width: 1040px;
        width: 100%;
        aspect-ratio: 7.3239;
      }
      .input-container-number {
        display: flex;
        flex-direction: column;
        gap: 12px;
        .div1 {
          display: flex;
          gap: 8px;
          .input {
            max-width: 1040px;
            width: 100%;
          }
          .button1 {
            padding: 13px 18px 13px 17px;
            justify-content: center;
            align-items: center;
            border-radius: 8px;
            background: var(--sub1, #ebf5f2);
            border: none;

            color: #154d57;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: -0.35px;
            font-weight: 400;
          }
        }
      }
      .input-field {
        padding: 11px 16px 11px 16px;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: -0.4px;
      }
    }
  }
}
.l {
  display: flex;
  gap: 4px;
  justify-content: end;
  align-items: end;
  max-width: 1040px;
  width: 100%;
  padding-top: 24px;

  .star {
    color: var(--600, #317580);
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
  }
  .w {
    color: #111;
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: -0.4px;
  }
}
.buttoncont {
  display: flex;
  justify-content: center;
  padding-top: 48px;

  .lbutton {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
    letter-spacing: -0.4px;
    color: #fff;
    background: var(--600, #317580);
    padding: 11px 86px;
    border-radius: 8px;
  }
}
