.price-cal{
    width: max-content;
    border-radius: 8px;
    border: 1px solid #D4D4D8;
    background: #FFF;
    min-width:100%;
    table-layout: fixed;
    overflow: hidden;
    .col-names{
        color: #505050;
font-family: Pretendard;
font-size: 13px;
font-style: normal;
font-weight: 600;
line-height: 19px; /* 146.154% */
letter-spacing: -0.325px;
background: var(--sub3, #F1F8FA);
        
    }

    td{
        width:100%;
        padding: 9px 12px;
        border: 1px solid #D4D4D8;
        border-right: none;
        input{
            border: none;
            outline: none;
            
        }
        input:focus,input:active{
            outline: none;
            border: none;
        }
    }
}
.scrollbarhidden::-webkit-scrollbar{
    display: none;
}