.patient-information {
  width: 100%;
  padding: 48px 32px;

  .patient-container {
    padding-top: 25px;
    padding-inline: 20px;
    padding-bottom: 20px;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0px 0px 8px 0px rgba(0, 56, 64, 0.06);

    display: flex;
    flex-direction: column;
    gap: 16px;

    .header {
      display: flex;
      justify-content: space-between;

      .left {
        display: flex;
        gap: 38px;

        h1 {
          color: #111;

          /* Subtitle2 KR Semibold */
          font-family: Pretendard;
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: 26px;
          /* 144.444% */
          letter-spacing: -0.45px;
        }

        .misc {
          display: flex;
          gap: 8px;
        }
      }

      .right {
        display: flex;
        gap: 8px;
        .blue {
          color: var(--blue-800, #1778b0);

          /* Body1 KR Semibold */
          font-family: Pretendard;
          font-size: 13px;
          font-style: normal;
          font-weight: 600;
          line-height: 19px;
          /* 146.154% */
          letter-spacing: -0.325px;
          padding: 9px 17px 8px 18px;
          border-radius: 8px;
          background: var(--blue-50, #dbeef9);
        }

        .green {
          color: var(--800, #154d57);

          /* Body1 KR Semibold */
          font-family: Pretendard;
          font-size: 13px;
          font-style: normal;
          font-weight: 600;
          line-height: 19px;
          /* 146.154% */
          letter-spacing: -0.325px;
          border-radius: 8px;
          background: var(--green-50, #d0e7dc);
          padding: 9px 23px 8px 23px;
        }
      }
    }

    .content {
    }
  }
}
