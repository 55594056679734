@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  margin: 0;

  background-color: #f1faf7;
  font-family: "Pretendard";
  background-image: url("./app//Assets//images//bggggg5.png");
  object-fit: cover;
}

@font-face {
  font-family: "Pretendard";
  src: url("../public/static/fonts/Pretendard-Regular.ttf");
  font-weight: 400;
}

/* @font-face {
  font-family: "Pretendard-Bold";
  src: url("../public/static/fonts/Pretendard-Bold.otf");
  font-weight: 700;
} */

.trt-btn.active {
  border-radius: 4px;
  background: var(--600, #317580);
  color: #fff;
  font-family: Pretendard;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 150% */
}

