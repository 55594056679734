/* Add this CSS in your stylesheet or style tag */
/* Add this CSS in your stylesheet or style tag */
/* Add this CSS in your stylesheet or style tag */
input[type="radio"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid #317580; /* Border color for unchecked state */
  border-radius: 50%;
  outline: none;
  cursor: pointer;
  position: relative;
}

input[type="radio"]:checked {
  background-color: #fff; /* Background color for checked state */
  border-color: #317580; /* Border color for checked state */
}

input[type="radio"]:checked::after {
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  background-color: #317580; /* Color of the central part when checked */
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Optional: Add some hover effect */
input[type="radio"]:hover {
  filter: brightness(1.2);
}

.patient-form-container {
  display: flex;
  flex-direction: column;
  gap: 32px;
  justify-content: center;
  align-items: center;

  h1 {
    color: var(--800, #154d57);
    text-align: center;

    /* H1 Headline KR Semibold */
    font-family: Pretendard;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 48px;
    /* 133.333% */
    letter-spacing: -0.9px;
  }

  .patient-form {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 48px;
    padding-inline: 20px;
    width: 100%;
    max-width: 1440px;
    border-radius: 8px;
    padding: 48px;
    background: #fff;
    /* 그림자 */
    box-shadow: 0px 0px 8px 0px rgba(0, 56, 64, 0.06);

    .form {
      width: 100%;
      max-width: 1040px;

      display: flex;
      flex-direction: column;
      gap: 40px;

      .main-question-container {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .main-question {
          color: #111;
          font-family: Pretendard;
          font-size: 18px;
          font-style: normal;
          font-weight: bold;
          line-height: 26px;
          /* 144.444% */
          letter-spacing: -0.45px;
        }

        .sub-questions {
          display: flex;
          flex-direction: column;
          gap: 32px;

          .sub-question-container {
            display: flex;
            flex-direction: column;
            gap: 12px;

            .sub-question {
              color: #111;
              font-family: Pretendard;
              font-size: 18px;
              font-style: normal;
              font-weight: 600;
              line-height: 26px;
              /* 144.444% */
              letter-spacing: -0.45px;
            }

            .answer {
              display: flex;
              flex-direction: column;
              gap: 12px;
              color: #111;
              font-family: Pretendard;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 24px;
              /* 150% */
              letter-spacing: -0.4px;

              input[type="checkbox"] {
                border-radius: 50%;
                stroke-width: 1px;
                border-color: #d4d4d8;
              }
              input[type="checkbox"]:checked {
                border: none !important;
              }

              label {
                display: flex;
                word-wrap: normal;
                align-items: center;
                p {
                  width: 100px;
                }
              }

              & > div {
                display: flex;
                align-items: center;
              }
            }
          }
        }
      }
    }

    .btn-container {
      button {
        color: #fff;

        /* Subtitle3  KR Semibold */
        font-family: Pretendard;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 150% */
        letter-spacing: -0.4px;
        display: flex;
        width: 200px;
        padding: 11px 86px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 8px;
        background: var(--600, #317580);
      }
    }
  }
}

.radio-bool {
  display: flex;
  flex-direction: row !important;
  gap: 24px !important;
}
