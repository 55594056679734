/* Modal.css */
.modal-alert {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content-alert {
  background-color: white;

  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}

.modal-title {
  font-size: 28px;
  font-weight: bold;
  text-align: center;
  color: #154d57;
}

:root {
  --my-custom-gradient: linear-gradient(
    180deg,
    #a0afb2 0%,
    rgba(160, 175, 178, 0.3) 90.91%
  );
}
