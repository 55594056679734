.ButtonUpload {
  display: inline-flex;

  padding: 47px 322px 47px 323px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid #d4d4d8;
  background: #fff;
  //  max-width: 740px;
  width: 100%;
  aspect-ratio: 7.3239;
}
