SuccessAlert.css .success-alert {
  text-align: center;
}

.swal2-html-container {
  margin: 32px 0 0 0;
}
.swal2-actions {
  margin: 0 0 0 0;
}

.success-subtitle {
  font-size: 14px;
  color: #767676;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.35px;
}

.success-title {
  font-size: 20px;
  color: #154d57;
  margin-bottom: 20px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: -0.5px;
}

.close-button-class {
  background-color: #d9534f !important;
}

.success-popup {
  max-width: 420px;
  width: 100%;
  padding: 0;
  border-radius: 8px;
}
/* Add this CSS to your SuccessAlert.css or index.css file */
.swal2-confirm.swal2-styled {
  margin-top: 32.5px;
  margin-bottom: 32.5px;
  padding: 8px 43px;
  background-color: #317580;
  border: none;
  border: 1px solid rgb(214, 241, 208);
  color: #fff;
  font-size: 18px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: none;
}

.swal2-confirm.swal2-styled:focus {
  box-shadow: none;
}

/* Hover effect for the Confirm button */

/* index.css */

/* Style for the secondary button */
.secondary-button {
  background-color: #ebf5f2; /* Change to your desired background color */
  color: #154d57; /* Change to your desired text color */
  padding: 8px 37px; /* Adjust padding as needed */
  margin-right: 8px; /* Add margin as needed */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  outline: none;
  border-radius: 8px;
  font-size: 13px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: -0.325px;
}

/* Style for the confirm button */
.confirm-button {
  background-color: #317580; /* Change to your desired background color */
  color: #ffffff; /* Change to your desired text color */
  padding: 8px 18px; /* Adjust padding as needed */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  outline: none;
  border-radius: 8px;
  font-size: 13px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: -0.325px;
}

/* Add any additional styles you need for the button-container div */
.button-container {
  margin-top: 32px; /* Add margin as needed */
  text-align: center; /* Align buttons to the center if needed */
  margin-bottom: 30px;
}
