/* @import url("https://fonts.googleapis.com/css2?family=Muli&display=swap");

:root {
  --line-border-fill: #3498db;
  --line-border-empty: #e0e0e0;
}

* {
  box-sizing: border-box;
} */

/* .progress-container {
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-bottom: 30px;
  max-width: 640px;
  width: 100%;
} */

.progress-container::before {
  content: ""; /* Mandatory with ::before */
  background-color: var(--line-border-empty);
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  height: 4px;
  width: 100%;
  z-index: 5;
}

.progress {
  background-color: #dda93f;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  height: 4px;
  width: 0%;
  z-index: -1;
  transition: 0.4s ease;
}

.circle {
  background: #ffffff;
  color: #999;
  border-radius: 50%;
  height: 56px;
  width: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.4s ease;
  font-size: 24px;
  border: 3px solid #d4d4d8;
  position: relative;
  z-index: 10;
  font-weight: 600;
}

.circle.active {
  background: linear-gradient(to right, #dda93f, #f1d397);
  border: none;
  color: #ffffff;
}

.circletext {
  color: black;
  font-weight: 600;
}
.circletext.active-text {
  color: #dda93f;
  font-weight: 600;
}

.circletext.active-text1 {
  color: #dda93f;
  font-size: 24px;
  font-weight: 600;
}

.circletext1 {
  color: #dda93f;
  font-size: 24px;
  font-weight: 600;
}

.btn {
  background-color: var(--line-border-fill);
  color: #fff;
  border: 0;
  border-radius: 6px;
  cursor: pointer;
  font-family: inherit;
  padding: 8px 30px;
  margin: 5px;
  font-size: 14px;
}

.btn:active {
  transform: scale(0.98);
}

.btn:focus {
  outline: 0;
}

.btn:disabled {
  background-color: var(--line-border-empty);
  cursor: not-allowed;
}

@media (max-width: 768px) {
  .circle {
    height: 28px;
    width: 28px;
    font-size: 14px;
  }
}
