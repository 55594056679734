.main-container {
  justify-content: center;
  align-items: center;
  padding-inline: 20px;
  .heading {
    color: var(--800, #154d57);
    text-align: center;

    /* H1 Headline KR Semibold */
    font-family: Pretendard;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 48px; /* 133.333% */
    letter-spacing: -0.9px;
    padding-top: 48px;
    padding-bottom: 32px;
  }
  .formContainerMain {
    display: flex;
    justify-content: center;
    padding-bottom: 160px;
    .formContainer {
      padding-inline: 20px;
      max-width: 1440px;
      width: 100%;
      padding-top: 48px;
      padding-bottom: 48px;

      flex-shrink: 0;

      border-radius: 8px;
      background: rgb(255, 255, 255);

      /* 그림자 */
      box-shadow: 0px 0px 8px 0px rgba(0, 56, 64, 0.06);
      justify-content: center;
      align-items: center;
      .headingSelection {
        display: flex;
        justify-content: center;
        align-items: center;

        .green,
        .gray {
          &[active="true"] {
            background-color: #3d8491;
            div {
              color: #fff !important;
            }
          }

          &:not([active="true"]) {
            background-color: #eaf6f3;
            div {
              color: #317580 !important;
            }
          }
        }
        .green {
          width: 520px;
          padding: 13px 0px;
          justify-content: center;
          align-items: center;
          border-radius: 8px 0px 0px 8px;

          .text-W {
            color: #fff;
            text-align: center;

            /* Subtitle1 KR Semibold */
            font-family: Pretendard;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: 30px; /* 150% */
            letter-spacing: -0.5px;
          }
        }
        .gray {
          display: flex;
          width: 520px;
          padding: 13px 0px;
          justify-content: center;
          align-items: center;
          border-radius: 0px 8px 8px 0px;

          .text-G {
            text-align: center;

            /* Subtitle1 KR Semibold */
            font-family: Pretendard;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 30px; /* 150% */
            letter-spacing: -0.5px;
          }
        }
      }
      .formContent {
        display: flex;
        flex-direction: column;
        padding-top: 40px;
        gap: 32px;
        .input1 {
          display: flex;
          flex-direction: column;
          gap: 16px;
          .Q1 {
            display: flex;
            flex-direction: row;
            gap: 4px;
            .text {
              color: #111;

              /* Subtitle3  KR Semibold */
              font-family: Pretendard;
              font-size: 16px;
              font-style: normal;
              font-weight: 600;
              line-height: 24px; /* 150% */
              letter-spacing: -0.4px;
            }
            .star {
              color: var(--500, #3d8491);
              font-family: Pretendard;
              font-size: 16px;
              font-style: normal;
              font-weight: 600;
              line-height: 24px; /* 150% */
            }
          }
          .A1 {
            .input-container {
              display: flex;
              flex-direction: column;

              .div1 {
                display: flex;
                gap: 8px;
                .input {
                  width: 922px;
                  height: 46px;
                }
                .button1 {
                  display: inline-flex;
                  padding: 13px 18px 13px 17px;
                  justify-content: center;
                  align-items: center;
                  border-radius: 8px;
                  background: var(--sub1, #ebf5f2);

                  max-width: 110px;
                  width: 100%;

                  .textB {
                    color: var(--800, #154d57);
                    font-family: Pretendard;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 20px; /* 142.857% */
                    letter-spacing: -0.35px;
                  }
                }
              }
            }
          }
        }
      }
      .l {
        display: flex;
        gap: 4px;
        justify-content: end;
        align-items: end;
        max-width: 1040px;
        width: 100%;
        padding-top: 24px;

        .star {
          color: var(--600, #317580);
          font-family: Pretendard;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px; /* 150% */
        }
        .w {
          color: #111;
          font-family: Pretendard;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px; /* 150% */
          letter-spacing: -0.4px;
        }
      }
    }
  }
}
